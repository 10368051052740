<template>
  <order-detail-wrapper :status="orderData.exa_status">
    <section-title>审核回复内容</section-title>
    <notice :content="orderData.fail_exp || '--' | decodeHTML"></notice>
    <section-title>理赔申请的注意事项</section-title>
    <notice :content="insurance.instype_explain | decodeHTML"></notice>

    <section-title>设备正面照</section-title>
    <img-display2to1 :src="orderData.img"></img-display2to1>

    <section-title>身份证正面照</section-title>
    <img-display2to1 :src="orderData.card"></img-display2to1>

    <template v-if="orderData.receive">
      <section-title>接件单正面照</section-title>
      <img-display2to1 :src="orderData.receive"></img-display2to1>
    </template>

    <template v-if="orderData.tem_back">
      <section-title>手机后盖照</section-title>
      <img-display2to1 :src="orderData.tem_back"></img-display2to1>
    </template>

    <section-title>用户信息</section-title>

    <van-cell title="姓名" :value="orderData.name || '--'"></van-cell>
    <van-cell title="身份证号" :value="orderData.id_card || '--'"></van-cell>
    <van-cell title="手机号码" :value="orderData.phone || '--'"></van-cell>
    <van-cell title="IMEI号" :value="orderData.imei || '--'"></van-cell>
    <van-cell title="手机品牌" :value="orderData.brand_name || '--'"></van-cell>
    <van-cell title="手机型号" :value="orderData.model_name || '--'"></van-cell>
    <template v-if="insurance.instype_id !== 1">
      <van-cell title="手机规格" :value="orderData.ram_size || '--'"></van-cell>
      <template v-if="orderData.type === 2">
        <van-cell title="网络制式" :value="orderData.net_name || '--'"></van-cell>
        <van-cell title="手机价格" :value="orderData.bare_price || '--'"></van-cell>
      </template>
    </template>

    <!--    <van-cell title="子保险" class="multi-line" v-if="orderData.son">-->
    <!--      <span v-html="orderData.son.map(item=>(item.son_name)).join('<br>')"></span>-->
    <!--    </van-cell>-->
    <!--    <van-cell title="子保险" class="multi-line" v-else>&#45;&#45;</van-cell>-->

    <van-cell title="子保险" class="multi-line" v-if="orderData.son_text">
      <span v-html="orderData.son_text.split(',').join('<br>')"></span>
    </van-cell>
    <van-cell title="子保险" class="multi-line" v-else>--</van-cell>

    <van-cell title="服务选择" :value="orderData.ins_service_name || '--'"></van-cell>
    <van-cell v-if="orderData.ybao_reason" title="延保原因" :value="orderData.ybao_reason || '--'"></van-cell>
    <van-cell title="损坏状况" class="multi-line" :value="orderData.damage_title || '--'"></van-cell>

    <template v-if="orderData.elsie">
      <section-title>爱思助手/沙漏验机截图</section-title>
      <img-display2to1 :src="orderData.elsie"></img-display2to1>
    </template>

    <van-cell title="期望城市" :value="orderData.repair_city"></van-cell>

    <!--    <template v-if="orderData.exa_status===1">-->
    <template>
      <div class="qrcode" v-if="orderData.exa_status === 1">
        <img :src="orderData.qr_url" alt="" />
        <span>维修商扫码维修</span>
      </div>
    </template>

    <section-title>订单信息</section-title>

    <notice>
      <div class="notice-item">
        <div class="notice-name">订单编号</div>
        <div class="notice-value">{{ orderData.claims_sn }}</div>
      </div>
      <div class="notice-item">
        <div class="notice-name">提交时间</div>
        <div class="notice-value">{{ orderData.add_time }}</div>
      </div>
      <div class="notice-item" v-if="[1, 3, 4, 5, 6, 7].indexOf(orderData.exa_status) !== -1">
        <div class="notice-name">审核通过时间</div>
        <div class="notice-value">{{ orderData.exa_time }}</div>
      </div>
      <!--      <div class="notice-item" v-if="orderData.exa_status===1">-->
      <!--        <div class="notice-name">生效期限</div>-->
      <!--        <div class="notice-value">{{ orderData.term_time_start }}-{{ orderData.term_time_end }}</div>-->
      <!--      </div>-->
      <div class="notice-item">
        <div class="notice-name">接待门店</div>
        <div class="notice-value">{{ orderData.shop_name }}</div>
      </div>
      <div class="notice-item">
        <div class="notice-name">接待人员</div>
        <div class="notice-value">{{ orderData.shop_member_name }}</div>
      </div>
      <div class="notice-item">
        <div class="notice-name">注册协议</div>
        <div class="notice-value blue" v-if="insurance.instype_name" @click="isShowInsTypeAgree = true">
          《{{ insurance.instype_name }}》
        </div>
        <div class="notice-value" v-else>--</div>
      </div>
      <van-popup round v-model="isShowInsTypeAgree" closeable close-icon="close">
        <div class="pop-wrapper">
          <div v-html="decodeHTML(insurance.instype_agree)" class="instype_agree rich-text"></div>
        </div>
      </van-popup>
    </notice>

    <my-button class="edit-btn" v-if="orderData.exa_status === 2 && insurance.instype_id !== 4"
      v-show="$route.query.canOperate !== '0'" @click="edit">编辑订单</my-button>
  </order-detail-wrapper>
</template>

<script>
import { getOrderClaimsInfo, getOrderInfo2 } from "@/api/claimSettlement";
import { decodeHTML } from "@/common/filters";

export default {
  name: "detail",
  props: {
    id: [String, Number],
  },
  data() {
    return {
      orderData: {},
      insurance: {},
      isShowInsTypeAgree: false,
    };
  },
  computed: {
    decodeHTML() {
      return (str) => {
        return decodeHTML(str);
      };
    },
  },
  mounted() {
    console.log(this);
    getOrderClaimsInfo(this.id).then((res) => {
      this.orderData = res.data.order;
      this.insurance = res.data.insurance_type;
    });
  },
  methods: {
    edit() {
      // getOrderInfo2({
      //   imei: this.orderData.imei,
      //   instype_id: this.insurance.instype_id
      // }).then(res => {
      //
      // })
      let type;
      switch (this.insurance.instype_id) {
        case 1:
          type = "brokenScreen";
          break;
        case 2:
          type = "android";
          break;
        case 3:
          type = "iphone";
          break;
        case 5:
          type = "smartChoice";
          break;
        case 6:
          type = "huawei";
          break;
        case 7:
          type = "iphoneSmart";
          break;
        case 8:
          type = "yanbaofuwu";
          break;
        case 9:
          type = "dianchifuwu";
          break;
        case 10:
          type = "upServe";
          break;
        //todo 新增保险要改的地方
        case 11:
          type = "houdun";
          break;
        case 13:
          type = "zuansu";
          break;
      }
      this.$router.push({
        name: "ClaimSettlementApply",
        params: { type },
        query: {
          instype_id: this.insurance.instype_id,
          claims_id: this.orderData.claims_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.notice-item {
  display: flex;
  line-height: 60px;
}

.notice-name {
  color: #666;
  min-width: 220px;
  white-space: nowrap;
}

.notice-value {
  word-break: break-all;
}

.edit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}

.blue {
  color: @blue;
}

.qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 371px;
    height: 366px;
    margin: 32px 0 12px;
  }

  span {
    font-size: 28px;
    font-weight: 500;
    color: #999;
    letter-spacing: 10px;
  }
}

.edit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}

.instype_agree {
  border-radius: 20px;
}

.pop-wrapper {
  max-height: 80vh;
  width: 80vw;
  overflow-y: auto;
  box-sizing: border-box;
}

.van-popup--center {
  border-radius: 20px;
  padding: 60px 30px 30px;
}

::v-deep .van-popup__close-icon--top-right {
  top: 10px;
  right: 10px;
}

.multi-line ::v-deep .van-cell__value {
  line-height: 1.5em;
  vertical-align: middle;
  display: inline-block;
  padding: 25px 0;
}

::v-deep .rich-text img {
  max-width: 100%;
}
</style>
